<template>
  <ion-list v-if="loadedChargeable">
    <ion-list-header>
      <h4>Chargeable:{{ loadedChargeable.name }}</h4>
    </ion-list-header>
    <ion-item lines="none">
      <p>{{ loadedChargeable.description }}</p>
    </ion-item>
    <ion-item>
      <p>
        Amount (Without tax*): Rs {{ loadedChargeable.amount_in_cent / 100 }}/-
      </p>
    </ion-item>
  </ion-list>
  <ion-list v-if="resources.data.length > 0" class="ion-margin-top">
    <ion-item v-for="item in resources.data" :key="item.id">
      <ion-label>
        <h2>
          {{ item.user.user_detail.name }}
        </h2>
        <ion-note
          >Class:
          {{
            `${item.section_standard.standard.name} - ${item.section_standard.section.name}`
          }}, Roll: {{ item.roll_no }}</ion-note
        >
      </ion-label>
      <ion-note v-if="isMandatory" slot="end">Mandatory</ion-note>
      <ion-button v-else slot="end" @click="confirmDelete(item)" fill="clear">
        <ion-icon
          slot="icon-only"
          :ios="trashOutline"
          :md="trashSharp"
          size="small"
          style="color: #eb445a"
        ></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No students found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonNote,
  IonButton,
  IonIcon,
  alertController,
} from "@ionic/vue";

import { trashOutline, trashSharp } from "ionicons/icons";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    chargeableId: {
      required: true,
    },
    loadedChargeable: {
      required: true,
    },
  },

  emits: ["refresh"],

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonNote,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      updated: false,
      xhrError: {
        header: null,
        description: null,
      },

      trashOutline,
      trashSharp,
    };
  },

  computed: {
    isMandatory() {
      const mandatory =
        this.loadedChargeable.is_mandatory == true ? true : false;
      return mandatory;
    },
  },

  methods: {
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async confirmDelete(resource) {
      const header = `Confirm`;
      const message = `Unassign "${resource.user.user_detail.name}" from chargeable "${this.loadedChargeable.name}"`;
      const buttons = [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.delete(resource.pivot.id);
          },
        },
      ];
      const alertDialog = await this.alerter(header, message, buttons);
      alertDialog.present();
    },

    async delete(id) {
      try {
        await this.$store.dispatch("chargeableStudent/deleteResource", {
          resourceId: id,
          chargeableId: this.chargeableId,
        });
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },
  },

  beforeUpdate() {
    this.updated = false;
  },

  updated() {
    this.updated = true;
  },
};
</script>
